<template>
     <Dashboard />
</template>

<script>


import Dashboard from '@/components/home/Dashboard.vue'

export default {
  name: 'HomeView',
  components: {
    Dashboard
  },
}
</script>
