<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const defaultLayout = 'default';

    const { currentRoute } = useRouter();

    const layout = computed(() => {
      return `${currentRoute.value.meta.layout || defaultLayout}-layout`;
    });
    return {
      layout
    };
  }
};
</script>
